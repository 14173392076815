.gameContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.preloadContainer {
    /* display the first child, and render the second invisibly to preload its resources */
    display: contents;
    overflow: hidden;
}
.preloadContainer > :nth-child(2) {
    display: block;
    height: 0;
    visibility: hidden;
}

.notesContainer {
    /* used in Notes.js */
    z-index: 25;
}
