.bg::after {
    content: "";
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-image: url(../content/breisheetShared/art/transition.png);
    background-size: cover;
    background-position: center;
    /* background-repeat: no-repeat; */
}

/* .scrollContainer>* {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
} */
.scrollContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.scrollRight {
    /* right: 0; */
    animation: scrollBounce 5s linear both;
}
.scrollLeft {
    animation: scrollBounce 5s linear both;
    /* transition: right 2s; */
    /* margin-right: 4px; */
}
.scrollMiddleContainer {
    overflow: hidden;
    /* width: 539px; */
}
.scrollMiddleScrollingContainer {
    display: flex;
    flex-direction: row;
    animation: scroll 5s linear both;
    width: 200%;
}
.scrollMiddleTextContainer {
    /* display: contents; */
    position: relative;
}
.scrollMiddle {
    height: 100%;
}
.scrollMiddleText {
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
}
.scrollMiddleText span[lang="he"] {
    font-family: 'Stam Ashkenaz CLM', serif;
    font-size: 500%;
}
.scrollMiddleText span[lang="en"] {
    font-family: 'Berry Rotunda', serif;
    font-size: 350%;
}

@keyframes scroll {
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(0);
    }
}


@keyframes scrollBounce {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(17px);
    }
    61% {
        transform: translateY(-24px);
    }
    85% {
        transform: translateY(-13px);
    }
    100% {
        transform: translateY(0);
    }
}
