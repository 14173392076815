.backpack {
    /* position: absolute;
    top: 20%;
    left: 20%;
    height: 60%;
    width: 50%;
    z-index: 20;

    background-color: aliceblue;
    padding: 10px; */
    z-index: 20;
}

.header {
    display: flex;
    height: 40px;
    border-bottom: 1px solid;
    align-items: center;
}

/* .backButton {
    align-self: flex-start;
}
.closeButton {
    align-self: flex-end;
} */
.spacer {
    flex: 1;
}

.content {
    padding: 5px;
    height: 100%;
    width: 100%;
}

.zoomableImageZoomed {
    cursor: zoom-out;
}
.zoomableImageNotZoomed {
    cursor: zoom-in;
}

.pdfContainer {
    width: fit-content;
}
.pdfPage {
    margin: 25px 10px;
}
.pdfPage:first-child {
    margin-top: 10px;
}
.pdfPage:last-child {
    margin-bottom: 10px;
}
