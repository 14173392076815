body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  overflow: hidden;
}

/* enable scroll on mobile */
@media (hover: none) {
  body {
    overflow: auto;
  }
}

html {
  font-size: 11px;
}
@media(min-height: 600px) {
  html {
    font-size: 14px;
  }
}
@media(min-height: 700px) {
  html {
    font-size: 17px;
  }
}
@media(min-height: 900px) {
  html {
    font-size: 21px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#error {
  margin: 2rem;
}
#error a {
  color: #0000EE;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@font-face {
  font-family: 'DSeg-7';
  src: url(./media/DSEG7ModernMini-Regular.woff2) format("woff2");
}
@font-face {
  font-family: 'Dorian CLM Italic';
  src: url('./media/Dorian CLM Book Italic.ttf') format("truetype");
}
@font-face {
  font-family: 'Stam Ashkenaz CLM';
  src: url('./media/Stam Ashkenaz CLM Medium.ttf') format("truetype");
}
@font-face {
  font-family: 'Berry Rotunda';
  src: url('./media/Berry Rotunda.ttf') format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Niconne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
