.pulse {
    color: #b8e4ff;
    filter: drop-shadow(0 0 4px) brightness(100%);
    animation: pulse 2s linear 1s infinite;
}

@keyframes pulse {
    0% {
        color: #b8e4ff;
        filter: drop-shadow(0 0 4px) brightness(100%);
    }

    50% {
        color: #80cfff;
        filter: drop-shadow(0 0 16px) brightness(125%);
    }

    100% {
        color: #b8e4ff;
        filter: drop-shadow(0 0 4px) brightness(100%);
    }
}
