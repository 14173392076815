.footer {
    height: 100px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #999999;
    background-color: #072f7b;
}

.footerItem {
    flex: 1;
    border-left: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footerItem:first-child {
    border-left: none;
}

.rtl .footerItem {
    border-right: 1px solid #999999;
    border-left: none;
}
.rtl .footerItem:first-child {
    border-right: none;
}

.footerLocation {
    flex-direction: column;
}
.footerLogo, .footerLocation {
    color: white;
    text-align: center;
}

.footerButtons {
    justify-content: space-evenly;
}
.footerButton {
    margin: 5px;
    font-size: 20px;
    background: #024682;
    color: white;
    border-radius: 60px;
    width: 60px;
    height: 60px;
    border: 0;
}

.footerLocation h3, .footerTitle {
    font-weight: bold;
    font-size: large;
}

.footerLogo {
    flex-direction: row;
}
.footerLogoPiece {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 3;
}
.footerLogoPiece:first-child {
    height: 100%;
    flex: 1;
}
.footerTitle, .footerClueCounter {
    margin: 4px 0;
}
.footerLogoPiece img {
    max-height: 90%;
}
