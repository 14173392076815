.wrapper {
    padding-right: 8px;
}

.body {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.header {
    cursor: move;
}
