@import url(https://fonts.googleapis.com/css2?family=Niconne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  overflow: hidden;
}

/* enable scroll on mobile */
@media (hover: none) {
  body {
    overflow: auto;
  }
}

html {
  font-size: 11px;
}
@media(min-height: 600px) {
  html {
    font-size: 14px;
  }
}
@media(min-height: 700px) {
  html {
    font-size: 17px;
  }
}
@media(min-height: 900px) {
  html {
    font-size: 21px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#error {
  margin: 2rem;
}
#error a {
  color: #0000EE;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@font-face {
  font-family: 'DSeg-7';
  src: url(/static/media/DSEG7ModernMini-Regular.aaf861a7.woff2) format("woff2");
}
@font-face {
  font-family: 'Dorian CLM Italic';
  src: url("/static/media/Dorian CLM Book Italic.c03a38df.ttf") format("truetype");
}
@font-face {
  font-family: 'Stam Ashkenaz CLM';
  src: url("/static/media/Stam Ashkenaz CLM Medium.de705902.ttf") format("truetype");
}
@font-face {
  font-family: 'Berry Rotunda';
  src: url("/static/media/Berry Rotunda.cc9ce3b0.ttf") format("truetype");
}

.styles_clueText__1yqo4 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14pt;
}

.styles_clueText__1yqo4 p, .styles_hintText__1GSFN p {
    margin: 12px 1px;
}

.styles_clueText__1yqo4 h3 {
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 0.5em;
}

.styles_clueText__1yqo4 h4 {
    font-size: 1.05em;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 0.2em;
}

.styles_clueText__1yqo4 a {
    color: #3182ce;
}
.styles_clueText__1yqo4 a:hover {
    color: #1e4e8c;
}

.styles_clueText__1yqo4 svg {
    display: inline;
}

.styles_clueText__1yqo4.styles_emBI__3coBW em {
    font-weight: bold;
    font-style: italic;
    color: darkred;
}

.styles_clueText__1yqo4 .styles_answerHint__1hd5Y, .styles_clueText__1yqo4 .styles_centeredClue__RYcQm {
    text-align: center;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
}

.styles_clueText__1yqo4 .styles_answerHint__1hd5Y>*,
.styles_clueText__1yqo4 .styles_centeredClue__RYcQm>* {
    margin-left: auto;
    margin-right: auto;
}

.styles_clueText__1yqo4 p.styles_centeredClue__RYcQm {
    display: block;
}

.styles_clueText__1yqo4 .styles_answerHint__1hd5Y {
    font-size: smaller;
    display: none;
}
/* hide all the underscores without going and editing them all */
.styles_clueText__1yqo4 .styles_answerHint__1hd5Y:last-child {
    display: block;
}

.styles_clueText__1yqo4 .styles_centeredClue__RYcQm {
    font-size: larger;
    font-family: Arial, Helvetica, sans-serif;
}
.styles_clueText__1yqo4 .styles_centeredClue__RYcQm.styles_small__3uiym {
    font-size: 1rem;
}

.styles_listIndent__3uMmJ {
    margin-left: 24px;
}

ul.styles_listSimple__2Oj63 li {
    list-style-type: initial;
    margin-left: 24px;
}

ul.styles_listNone__1MnHm li {
    list-style-type: none;
}

ol.styles_listSimple__2Oj63 li {
    list-style-type: decimal;
    margin-left: 24px;
}

.styles_listDashes__3bnE0 li {
    list-style-type: square;
    margin-left: 24px;
}

.styles_alphabetList__11jcc li {
    list-style-type: upper-latin;
    margin-left: 24px;
}

[dir="rtl"] .styles_listIndent__3uMmJ,
[dir="rtl"] .styles_listSimple__2Oj63 li,
[dir="rtl"] .styles_listDashes__3bnE0 li,
[dir="rtl"] .styles_alphabetList__11jcc li {
    margin-left: auto;
    margin-right: 24px;
}

.styles_letterTable__2CRyf {
    border-collapse: separate;
    border-spacing: 5px;
    text-align: center;
}

.styles_letterTableBordered__2cYY7 {
    margin: 4px;
}
.styles_letterTableBordered__2cYY7 td {
    border: 1px solid;
    padding: 5px;
}

.styles_preserveWhitespace__1IhNC {
    white-space: pre-line;
}
.styles_noWrap__2dvcL {
    white-space: nowrap;
}

.styles_clueText__1yqo4 hr {
    border-top-width: 1px;
    border-color: #333;
    margin: 12px auto;
    width: 50%;
}

.styles_shukImageContainer__qfeZd img {
    display: inline-block;
    height: 100px;
    margin: 4px auto;
}
.styles_clueText__1yqo4 .styles_citadelImageContainer__3fdcg img {
    display: inline-block;
    height: 75px;
    margin: 3px;
}

.styles_tombsOfRighteousPathTable__3TEjv {
    margin-bottom: 1rem;
}

.styles_tombsOfRighteousPathTable__3TEjv td {
    border: 1px solid #515151;
    padding: 3px;
    font-size: 1.1em;
    width: 60px;
    height: 60px;
    white-space: nowrap;
}
.styles_tombsOfRighteousPathTable__3TEjv tr:nth-child(odd) {
    color: white;
}
.styles_tombsOfRighteousPathTable__3TEjv tr:empty {
    height: 30px;
}

.rtl_rtlContainer__1BLG3 {
    direction: rtl;
    text-align: start;
}

.rtl_rtlContainer__1BLG3 label {
    text-align: start;
}

.GameContainer_gameContainer__-cISp {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.GameContainer_preloadContainer__251QN {
    /* display the first child, and render the second invisibly to preload its resources */
    display: contents;
    overflow: hidden;
}
.GameContainer_preloadContainer__251QN > :nth-child(2) {
    display: block;
    height: 0;
    visibility: hidden;
}

.GameContainer_notesContainer__2Iudl {
    /* used in Notes.js */
    z-index: 25;
}

.Footer_footer__3V1cF {
    height: 100px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #999999;
    background-color: #072f7b;
}

.Footer_footerItem__2BIsp {
    flex: 1 1;
    border-left: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Footer_footerItem__2BIsp:first-child {
    border-left: none;
}

.Footer_rtl__3_5cw .Footer_footerItem__2BIsp {
    border-right: 1px solid #999999;
    border-left: none;
}
.Footer_rtl__3_5cw .Footer_footerItem__2BIsp:first-child {
    border-right: none;
}

.Footer_footerLocation__3AekQ {
    flex-direction: column;
}
.Footer_footerLogo__1noLH, .Footer_footerLocation__3AekQ {
    color: white;
    text-align: center;
}

.Footer_footerButtons__DeFyS {
    justify-content: space-evenly;
}
.Footer_footerButton__bFxX_ {
    margin: 5px;
    font-size: 20px;
    background: #024682;
    color: white;
    border-radius: 60px;
    width: 60px;
    height: 60px;
    border: 0;
}

.Footer_footerLocation__3AekQ h3, .Footer_footerTitle__EjdvY {
    font-weight: bold;
    font-size: large;
}

.Footer_footerLogo__1noLH {
    flex-direction: row;
}
.Footer_footerLogoPiece__36fNf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 3 1;
}
.Footer_footerLogoPiece__36fNf:first-child {
    height: 100%;
    flex: 1 1;
}
.Footer_footerTitle__EjdvY, .Footer_footerClueCounter__1GKyU {
    margin: 4px 0;
}
.Footer_footerLogoPiece__36fNf img {
    max-height: 90%;
}

.MainContent_mainContainer__lBBDg {
    flex: 1 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.MainContent_mainContainerDialog__1cGda {
    transition: height 0.2s, width 0.2s;
}
.MainContent_mainContainerDialog__1cGda.MainContent_resizing__3dj7i {
    transition: none;
}

.MainContent_mainContent__3CbJe {
    width: 100%;
    /*! margin: auto; */
    /* border: 1px solid #d9d9d9; */
    /*! flex: 1 1; */
    /*! position: absolute; */
    z-index: 10;
    /* background: rgba(100,100,100,0.8); */
    /*! bottom: 5px; */
    /*! align-self: flex-end; */
    /* margin-bottom: 5px;
    padding: 0px 10px; */
}
.MainContent_collapseTextButton__1O0JJ, .MainContent_openTextButton__3cNTn {
    display: block;
    margin: auto;
    border-style: solid;
    height: 15px;
    line-height: 10px;
    width: 80px;
}
.MainContent_openTextButton__3cNTn {
    z-index: 10;
    margin-bottom: 5px;
}

/* .imgContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;

    background: url(./art/ben-gurion-airport.png) no-repeat;
    background-size: cover;
    /* background-position: bottom -20px center; * /
}*/

.Dialog_wrapper__1_HjU {
    padding-right: 8px;
}

.Dialog_body__wfRjZ {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.Dialog_header__1mAlv {
    cursor: move;
}

.Backpack_backpack__2UBm0 {
    /* position: absolute;
    top: 20%;
    left: 20%;
    height: 60%;
    width: 50%;
    z-index: 20;

    background-color: aliceblue;
    padding: 10px; */
    z-index: 20;
}

.Backpack_header__1-Dfv {
    display: flex;
    height: 40px;
    border-bottom: 1px solid;
    align-items: center;
}

/* .backButton {
    align-self: flex-start;
}
.closeButton {
    align-self: flex-end;
} */
.Backpack_spacer__3LyyF {
    flex: 1 1;
}

.Backpack_content__3tLvH {
    padding: 5px;
    height: 100%;
    width: 100%;
}

.Backpack_zoomableImageZoomed__jVMyz {
    cursor: zoom-out;
}
.Backpack_zoomableImageNotZoomed__1AwS0 {
    cursor: zoom-in;
}

.Backpack_pdfContainer__rpk7A {
    width: -webkit-fit-content;
    width: fit-content;
}
.Backpack_pdfPage__uq8xL {
    margin: 25px 10px;
}
.Backpack_pdfPage__uq8xL:first-child {
    margin-top: 10px;
}
.Backpack_pdfPage__uq8xL:last-child {
    margin-bottom: 10px;
}

.Intro_introWrapper__1ksDu p {
    margin: 16px auto;
    font-size: 16pt;
}

.Leaderboard_table__5tkq- {
    width: 100%;
}

.Leaderboard_myRow__3ZU2s {
    background-color: #ef7407;
}

.EndCertificate_logo__2Ra-N {
    max-height: 150px;
}

@media(min-height: 900px) {
    .EndCertificate_logo__2Ra-N {
        max-height: 200px;
    }
}

.Map_mapCircle__kEbG1 {
    position: absolute;
    border: 2px solid black;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: none;
    z-index: 10;
}
.Map_mapCircle__kEbG1.Map_ready__3kkzy {
    display: block;
    transition: all 3s;
}
.Map_mapCircle__kEbG1.Map_exit__33eEu {
    background-color: #072f7b;
    /* border-color: #072f7b; */
    transition: all 5s;
}

.PuzzleTransition_puzzleGrid__3xlO3 {
    display: grid;
    /* grid-template-rows: repeat(3, fit-content(300px));
    grid-template-columns: repeat(3, fit-content(300px)); */
    /* max-height: calc(100% - 100px); */
    height: 100%;
    /* max-width: 986px; */
    min-width: 0;
    min-height: 0;
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
    grid-template-columns: 1fr repeat(3, minmax(0, 333px)) 1fr;
    grid-template-rows: repeat(3, minmax(0, 1fr));
    grid-template-areas: ". A B C ." ". D E F ." ". G H I .";
    /* justify-items: normal;
    align-items: center; */
}

.PuzzleTransition_gridItem__3Dr-v {
    background-color: #b4b4b4;
    border: 1px solid black;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
    width: 100%;
    justify-self: center;
}
/* .gridItem:nth-child(3n+1) {
    grid-column: content 1;
} */

.PuzzleTransition_puzzleImage__3tyaV {
    max-width: 100%;
    height: auto;
    /* object-fit: contain; */
    height: 100%;
    width: 100%;
    z-index: 20;
}

.PuzzleTransition_moving__1cO2S {
    position: absolute;
    transition: 2.5s;
    transition-property: left, top;
}


.PuzzleTransition_locationsTrackContainer__3Iz3n, .PuzzleTransition_locationsTrackLabels__YpPqg {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 10px;
}
.PuzzleTransition_locationsTrackBubble__2SoYV {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 4px solid gray;
    margin: 0 -2px;
}
/* .locationsTrackBubble.newLocation {
    background-color: darkred;
} */
.PuzzleTransition_locationsTrackMovingBubble__2c-tZ {
    position: absolute;
    margin: 4px;
    width: 22px;
    height: 22px;
    border-radius: 20px;
    background-color: darkred;
    transition: 2s all;
}
.PuzzleTransition_locationsTrackConnector__eVsuQ {
    background-color: darkred;
    border: solid gray;
    border-width: 4px 0;
    height: 10px;
    flex: 1 1;
}
.PuzzleTransition_locationsTrackLabels__YpPqg {
    justify-content: space-between;
    align-items: center;
}
.PuzzleTransition_locationsTrackLabel__2JDRR {
    text-align: center;
    flex: 1 1;
    max-width: 100px;
    margin-left: -50px;
}
.PuzzleTransition_locationsTrackLabel__2JDRR.PuzzleTransition_newLocation__3UGCF {
    color: darkred;
}
.PuzzleTransition_locationsTrackLabel__2JDRR:first-child {
    text-align: left;
    margin: 0;
    max-width: 75px;
}
.PuzzleTransition_locationsTrackLabel__2JDRR:last-child {
    text-align: right;
    max-width: 75px;
}

@media (min-width: 1400px) {
    .PuzzleTransition_locationsTrackLabel__2JDRR {
        max-width: 120px;
        margin-left: -60px;
    }
}
@media (min-width: 1600px) {
    .PuzzleTransition_locationsTrackLabel__2JDRR {
        max-width: 150px;
        margin-left: -75px;
    }
    .PuzzleTransition_locationsTrackLabel__2JDRR:first-child,
    .PuzzleTransition_locationsTrackLabel__2JDRR:last-child {
        max-width: 100px;
    }
}


/* https://davidwalsh.name/css-flip */

/* entire container, keeps perspective */
.PuzzleTransition_flipContainer__2Q_O0 {
    -webkit-perspective: 1000px;
            perspective: 1000px;
}

/* flip the pane */
.PuzzleTransition_flipContainer__2Q_O0.PuzzleTransition_flipperBack__3h-Jw .PuzzleTransition_flipper__3EwQL {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

/* .flipContainer,
.front,
.back {
    width: 320px;
    height: 480px;
} */

/* flip speed goes here */
.PuzzleTransition_flipper__3EwQL {
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;

    position: relative;
    height: 100%;
}
.PuzzleTransition_flipContainer__2Q_O0.PuzzleTransition_flipperSlow__UYnM5 .PuzzleTransition_flipper__3EwQL {
    transition: 2s;
}

/* hide back of pane during swap */
.PuzzleTransition_front__wydL2,
.PuzzleTransition_back__1sfFU {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.PuzzleTransition_front__wydL2 {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

/* back, initially hidden pane */
.PuzzleTransition_back__1sfFU {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.Calendar_calendar__ABi_m {
    width: 90%;
    height: 90%;
    min-height: 500px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr;

    background-image: url(/static/media/calendar-bg.0660eefa.jpg);
}

.Calendar_day__1IxSM {
    /* height: 50px; */
    border: 1px solid #333;
    text-align: right;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.85);
}

.Calendar_outOfRangeDays__V6n-6 {
    background: none;
}

.Calendar_dayDone__1h3zY {
    background-color: lightblue;
}
.Calendar_dayActive__2wUgI {
    background-color: yellow;
}

.Calendar_lockContainer__1Y8bk {
    display: block;
    margin: auto;
    max-width: calc(100vh / 15);
    height: 100%;
    position: relative;
    left: -10%;
}
.Calendar_lockContainer__1Y8bk img {
    position: relative;
    top: 0;
    -webkit-transform-origin: 88% center;
            transform-origin: 88% center;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}
.Calendar_lockContainer__1Y8bk.Calendar_lockClosed__3SR2d img:first-child {
    top: 10%;
}
.Calendar_dayDone__1h3zY .Calendar_lockContainer__1Y8bk img:first-child {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.Calendar_dayActive__2wUgI .Calendar_lockContainer__1Y8bk {
    width: 10%;
    -webkit-animation: Calendar_lockGrow__2RiUq 3s 1s forwards;
            animation: Calendar_lockGrow__2RiUq 3s 1s forwards;
}
.Calendar_dayActive__2wUgI .Calendar_lockContainer__1Y8bk img:first-child {
    top: 10%;
    -webkit-animation: Calendar_openLock__3bDLT 3s 3s forwards;
            animation: Calendar_openLock__3bDLT 3s 3s forwards;
    /* transition: 1s transform; */
}

@-webkit-keyframes Calendar_openLock__3bDLT {
    0% {
        -webkit-transform: none;
                transform: none;
    }
    33% {
        top: 0;
        -webkit-transform: none;
                transform: none;
    }
    100% {
        top: 0;
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
}

@keyframes Calendar_openLock__3bDLT {
    0% {
        -webkit-transform: none;
                transform: none;
    }
    33% {
        top: 0;
        -webkit-transform: none;
                transform: none;
    }
    100% {
        top: 0;
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
}
@-webkit-keyframes Calendar_lockGrow__2RiUq {
    to {
        width: 100%;
    }
}
@keyframes Calendar_lockGrow__2RiUq {
    to {
        width: 100%;
    }
}


.Calendar_container__3s4Eg.Calendar_finalLevel__30zJ6 {
    position: relative;
    -webkit-animation: Calendar_revealScroll__2vvwx 3s 8s forwards;
            animation: Calendar_revealScroll__2vvwx 3s 8s forwards;
}

@-webkit-keyframes Calendar_revealScroll__2vvwx {
    from {
        top: 0;
    }
    to {
        top: -100vh;
    }
}

@keyframes Calendar_revealScroll__2vvwx {
    from {
        top: 0;
    }
    to {
        top: -100vh;
    }
}

.Calendar_endMessageTitle__27Zw- {
    position: relative;
    color: white;
    -webkit-text-stroke: 0.1rem black;
    font-weight: bold;
}

.Sefirot_pulser__16jWR {
    position: absolute;
    /* border: 2px solid black; */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: none;
    z-index: 10;
}
.Sefirot_pulser__16jWR.Sefirot_ready__1Np1n {
    display: block;
    transition: all 3s;
}

.Sefirot_pulser__16jWR {
    background-color: #fff;
    color: #80cfff;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    box-shadow: 0 0 8px, inset 0 0 8px;
    -webkit-animation: Sefirot_pulse__3YqRO 2s linear 1s infinite;
            animation: Sefirot_pulse__3YqRO 2s linear 1s infinite;
}

@-webkit-keyframes Sefirot_pulse__3YqRO {
    0% {
        box-shadow: 0 0 8px, inset 0 0 8px;
    }

    50% {
        box-shadow: 0 0 16px, inset 0 0 14px;
    }

    100% {
        box-shadow: 0 0 8px, inset 0 0 8px;
    }
}

@keyframes Sefirot_pulse__3YqRO {
    0% {
        box-shadow: 0 0 8px, inset 0 0 8px;
    }

    50% {
        box-shadow: 0 0 16px, inset 0 0 14px;
    }

    100% {
        box-shadow: 0 0 8px, inset 0 0 8px;
    }
}

/* based on https://codepen.io/laverboy/pen/iEDcG */

.TOL_pulse__2zsyW {
    color: #b8e4ff;
    -webkit-filter: drop-shadow(0 0 4px) brightness(100%);
            filter: drop-shadow(0 0 4px) brightness(100%);
    -webkit-animation: TOL_pulse__2zsyW 2s linear 1s infinite;
            animation: TOL_pulse__2zsyW 2s linear 1s infinite;
}

@-webkit-keyframes TOL_pulse__2zsyW {
    0% {
        color: #b8e4ff;
        -webkit-filter: drop-shadow(0 0 4px) brightness(100%);
                filter: drop-shadow(0 0 4px) brightness(100%);
    }

    50% {
        color: #80cfff;
        -webkit-filter: drop-shadow(0 0 16px) brightness(125%);
                filter: drop-shadow(0 0 16px) brightness(125%);
    }

    100% {
        color: #b8e4ff;
        -webkit-filter: drop-shadow(0 0 4px) brightness(100%);
                filter: drop-shadow(0 0 4px) brightness(100%);
    }
}

@keyframes TOL_pulse__2zsyW {
    0% {
        color: #b8e4ff;
        -webkit-filter: drop-shadow(0 0 4px) brightness(100%);
                filter: drop-shadow(0 0 4px) brightness(100%);
    }

    50% {
        color: #80cfff;
        -webkit-filter: drop-shadow(0 0 16px) brightness(125%);
                filter: drop-shadow(0 0 16px) brightness(125%);
    }

    100% {
        color: #b8e4ff;
        -webkit-filter: drop-shadow(0 0 4px) brightness(100%);
                filter: drop-shadow(0 0 4px) brightness(100%);
    }
}

.Chanukiah_flameBox__3b2pc, .Chanukiah_clue10TextBox__xzu7t {
    position: absolute;
    /* border: 2px solid black; */
    display: none;
    z-index: 5;
    -webkit-transform-origin: top left;
            transform-origin: top left;
}
.Chanukiah_flame__SG1ni {
    -webkit-transform: translate(-50%, 0%) scale(0.6);
            transform: translate(-50%, 0%) scale(0.6);
    max-width: none;
}

.Chanukiah_flameBox__3b2pc.Chanukiah_ready__27UTd, .Chanukiah_clue10TextBox__xzu7t.Chanukiah_ready__27UTd {
    display: block;
}
.Chanukiah_flameBox__3b2pc.Chanukiah_ready__27UTd .Chanukiah_flame__SG1ni.Chanukiah_up__3gLrG {
    transition: all 3s;
    -webkit-transform: translate(-50%, -100%) scale(0.9);
            transform: translate(-50%, -100%) scale(0.9);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
}

.Chanukiah_clue9Text__1U8Vn {
    /* display: none; */
    position: absolute;
    z-index: 30;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* width: 0; */
    transition: opacity 3s;
    font-family: 'Niconne', cursive, serif;
}
.Chanukiah_rtl__1Dv8Y .Chanukiah_clue9Text__1U8Vn {
    font-family: 'Dorian CLM Italic', serif;
    font-weight: bold;
}
.Chanukiah_clue9Text__1U8Vn.Chanukiah_shown__3gOjP {
    opacity: 100;
    /* width: auto; */
    /* display: block; */
}
.Chanukiah_clue9Text__1U8Vn.Chanukiah_shown__3gOjP::after {
    display: block;
    content: '';
    height: 1em;
    position: relative;
    top: -1.2em;
    margin-bottom: -1em;
    background: linear-gradient(to right, transparent, #e9ac59 10%);
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-animation: Chanukiah_revealText__2OAqn 3s ease-out both;
            animation: Chanukiah_revealText__2OAqn 3s ease-out both;
}
.Chanukiah_rtl__1Dv8Y .Chanukiah_clue9Text__1U8Vn.Chanukiah_shown__3gOjP::after {
    background: linear-gradient(to left, transparent, #e9ac59 10%);
    -webkit-transform-origin: left center;
            transform-origin: left center;
}
.Chanukiah_clue9Text__1U8Vn.Chanukiah_shown__3gOjP.Chanukiah_tallCover__1J9AO::after {
    height: 2em;
    top: -1.8em;
    margin-bottom: -2.1em;
}
@-webkit-keyframes Chanukiah_revealText__2OAqn {
    0% {
        -webkit-transform: scaleX(120%);
                transform: scaleX(120%);
    }
    100% {
        -webkit-transform: scaleX(0%);
                transform: scaleX(0%);
    }
}
@keyframes Chanukiah_revealText__2OAqn {
    0% {
        -webkit-transform: scaleX(120%);
                transform: scaleX(120%);
    }
    100% {
        -webkit-transform: scaleX(0%);
                transform: scaleX(0%);
    }
}

.Chanukiah_clue10Text__2x31W {
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    text-align: center;
    font-size: 4em;
    font-weight: bold;
    /* display: block; */
    z-index: 20;
    opacity: 0;
    transition: all 3s;
    /* position: absolute; */
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}
.Chanukiah_rtl__1Dv8Y .Chanukiah_clue10Text__2x31W {
    font-family: 'Niconne', cursive, serif;
    font-size: 8em;
    -webkit-transform: translate(-40%, -90%);
            transform: translate(-40%, -90%);
}
.Chanukiah_clue10Text__2x31W.Chanukiah_shown__3gOjP {
    opacity: 100;
}
.Chanukiah_ltr__13c4H .Chanukiah_clue10TextBox__xzu7t:first-of-type .Chanukiah_clue10Text__2x31W {
    font-size: 3em;
    width: 2em;
    letter-spacing: 1em;
    word-break: break-all;
    -webkit-transform: translate(-25%, 0);
            transform: translate(-25%, 0);
    line-height: 1.2em;
}
.Chanukiah_rtl__1Dv8Y .Chanukiah_clue10TextBox__xzu7t:first-of-type .Chanukiah_clue10Text__2x31W {
    font-size: 12em;
    -webkit-transform: translate(-40%, -80%);
            transform: translate(-40%, -80%);
}
.Chanukiah_clue10TextBox__xzu7t {
    z-index: 20;
}

.Chanukiah_gelt__3jNne {
    display: block;
    position: absolute;
    z-index: 50;
    /* animation: geltFall linear both; */
    /* max-height: 100px; */
    opacity: 0;
}
@-webkit-keyframes Chanukiah_geltFall__32Mel {
    0% {
        bottom: 100vh;
        opacity: 100;
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        bottom: 0;
        opacity: 100;
    }
}
@keyframes Chanukiah_geltFall__32Mel {
    0% {
        bottom: 100vh;
        opacity: 100;
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        bottom: 0;
        opacity: 100;
    }
}

.Megillah_bg__3X9j4::after {
    content: "";
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-image: url(/static/media/transition.f095b813.png);
    background-size: contain;
    background-position: center;
    /* background-repeat: no-repeat; */
}

.Megillah_scrollRight__2EX40, .Megillah_scrollLeft__3u3Xk {
    position: absolute;
    top: 0;
    height: 100%;
}
.Megillah_scrollRight__2EX40 {
    right: 0;
}
.Megillah_scrollLeft__3u3Xk {
    transition: right 2s;
    /* margin-right: 4px; */
}

.Megillah_withBorder__1EsSt {
    --stroke-radius: 1px;
    -webkit-animation: Megillah_borderGrow__i3DGY 2s 0.1s ease-in both;
            animation: Megillah_borderGrow__i3DGY 2s 0.1s ease-in both;
}

@-webkit-keyframes Megillah_borderGrow__i3DGY {
    from {
        -webkit-filter: drop-shadow(1px 0 var(--stroke-radius) transparent)
                drop-shadow(-1px var(--stroke-radius) transparent)
                drop-shadow(0 1px var(--stroke-radius) transparent)
                drop-shadow(0 -1px var(--stroke-radius) transparent)
                drop-shadow(1px 1px var(--stroke-radius) transparent)
                drop-shadow(1px -1px var(--stroke-radius) transparent)
                drop-shadow(-1px 1px var(--stroke-radius) transparent)
                drop-shadow(-1px -1px var(--stroke-radius) transparent);
                filter: drop-shadow(1px 0 var(--stroke-radius) transparent)
                drop-shadow(-1px var(--stroke-radius) transparent)
                drop-shadow(0 1px var(--stroke-radius) transparent)
                drop-shadow(0 -1px var(--stroke-radius) transparent)
                drop-shadow(1px 1px var(--stroke-radius) transparent)
                drop-shadow(1px -1px var(--stroke-radius) transparent)
                drop-shadow(-1px 1px var(--stroke-radius) transparent)
                drop-shadow(-1px -1px var(--stroke-radius) transparent);
    }

    to {
        -webkit-filter: drop-shadow(1px 0 var(--stroke-radius) #00ccfb)
                drop-shadow(-1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 -1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px -1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px -1px var(--stroke-radius) #00ccfb);
                filter: drop-shadow(1px 0 var(--stroke-radius) #00ccfb)
                drop-shadow(-1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 -1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px -1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px -1px var(--stroke-radius) #00ccfb);
    }
}

@keyframes Megillah_borderGrow__i3DGY {
    from {
        -webkit-filter: drop-shadow(1px 0 var(--stroke-radius) transparent)
                drop-shadow(-1px var(--stroke-radius) transparent)
                drop-shadow(0 1px var(--stroke-radius) transparent)
                drop-shadow(0 -1px var(--stroke-radius) transparent)
                drop-shadow(1px 1px var(--stroke-radius) transparent)
                drop-shadow(1px -1px var(--stroke-radius) transparent)
                drop-shadow(-1px 1px var(--stroke-radius) transparent)
                drop-shadow(-1px -1px var(--stroke-radius) transparent);
                filter: drop-shadow(1px 0 var(--stroke-radius) transparent)
                drop-shadow(-1px var(--stroke-radius) transparent)
                drop-shadow(0 1px var(--stroke-radius) transparent)
                drop-shadow(0 -1px var(--stroke-radius) transparent)
                drop-shadow(1px 1px var(--stroke-radius) transparent)
                drop-shadow(1px -1px var(--stroke-radius) transparent)
                drop-shadow(-1px 1px var(--stroke-radius) transparent)
                drop-shadow(-1px -1px var(--stroke-radius) transparent);
    }

    to {
        -webkit-filter: drop-shadow(1px 0 var(--stroke-radius) #00ccfb)
                drop-shadow(-1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 -1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px -1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px -1px var(--stroke-radius) #00ccfb);
                filter: drop-shadow(1px 0 var(--stroke-radius) #00ccfb)
                drop-shadow(-1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 -1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px -1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px -1px var(--stroke-radius) #00ccfb);
    }
}

.Megillah_hamantash__Roz8q {
    display: block;
    position: absolute;
    left: 20vw;
    z-index: 50;
    visibility: hidden;
    -webkit-transform-origin: top right;
            transform-origin: top right;
}

@-webkit-keyframes Megillah_hamantashAppear__3DHTZ {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

@keyframes Megillah_hamantashAppear__3DHTZ {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

.Megillah_hamantashBig__2AQcp {
    display: block;
    position: absolute;
    left: 20vw;
    z-index: 50;
    -webkit-animation: Megillah_hamantashGrow__2wV9p 3s linear both;
            animation: Megillah_hamantashGrow__2wV9p 3s linear both;
    /* visibility: hidden; */
    -webkit-transform-origin: center;
            transform-origin: center;
}

@-webkit-keyframes Megillah_hamantashGrow__2wV9p {
    0% {
        -webkit-transform: rotate(1deg) scale(0.3) translateX(-25%);
                transform: rotate(1deg) scale(0.3) translateX(-25%);
    }

    100% {
        left: 40vw;
        -webkit-transform: rotate(540deg) scale(2) translateX(0);
                transform: rotate(540deg) scale(2) translateX(0);
    }
}

@keyframes Megillah_hamantashGrow__2wV9p {
    0% {
        -webkit-transform: rotate(1deg) scale(0.3) translateX(-25%);
                transform: rotate(1deg) scale(0.3) translateX(-25%);
    }

    100% {
        left: 40vw;
        -webkit-transform: rotate(540deg) scale(2) translateX(0);
                transform: rotate(540deg) scale(2) translateX(0);
    }
}

.Afikoman_bg__2536-::after {
    content: "";
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-color: #6c0f0f;
    /* background-image: url(../content/purimShared/art/transition.png);
    background-size: contain;
    background-position: center; */
    /* background-repeat: no-repeat; */
}

.Afikoman_afikomanBag__bcVE3 {
    max-height: 60%;
    max-width: 30%;
    position: absolute;
    bottom: 10px;
    left: 20%;
}
.Afikoman_afikomanBagFront__2qmt_ {
    z-index: 10;
}
.Afikoman_afikomanBagBack__gasfy {
    z-index: 5;
}

.Afikoman_matzah__292cy {
    max-height: 20%;
    max-width: 10%;
    position: absolute;
    left: 85%;
    top: 80%;
    z-index: 7;
    visibility: visible;
    transition: all 2s;
    -webkit-filter: drop-shadow(2px 2px 2px #333) drop-shadow(-1px -1px 2px #666);
            filter: drop-shadow(2px 2px 2px #333) drop-shadow(-1px -1px 2px #666)
}

.Afikoman_matzahPiecesContainer__2CT5c {
    width: 70vh;
    height: 70vh;
    max-width: 40vw;
    max-height: 40vw;
    position: absolute;
    top: 10px;
    right: 10px;
    -webkit-transform-origin: center;
            transform-origin: center;
}
.Afikoman_matzahPiece__32t-k {
    position: absolute;
    transition: visibility 2s 4.5s, -webkit-transform 2s 0.5s;
    transition: transform 2s 0.5s, visibility 2s 4.5s;
    transition: transform 2s 0.5s, visibility 2s 4.5s, -webkit-transform 2s 0.5s;
}

.Afikoman_matzahPiecesContainer__2CT5c.Afikoman_center__1Mybh {
    width: 15vw;
    height: 15vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 4s;
    z-index: 20;
}
.Afikoman_matzahPiecesContainer__2CT5c.Afikoman_big__29aJ- {
    max-width: 75vh;
    max-height: 75vh;
    width: 60vw;
    height: 60vw;
    -webkit-transform: rotate(3turn);
            transform: rotate(3turn);
}

.Torah_bg__3KcuX::after {
    content: "";
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-image: url(/static/media/transition.f9c2ee04.png);
    background-size: cover;
    background-position: center;
    /* background-repeat: no-repeat; */
}

/* .scrollContainer>* {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
} */
.Torah_scrollContainer__2J6C6 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Torah_scrollRight__4IB1t {
    /* right: 0; */
    -webkit-animation: Torah_scrollBounce__3UDrm 5s linear both;
            animation: Torah_scrollBounce__3UDrm 5s linear both;
}
.Torah_scrollLeft__2EhoO {
    -webkit-animation: Torah_scrollBounce__3UDrm 5s linear both;
            animation: Torah_scrollBounce__3UDrm 5s linear both;
    /* transition: right 2s; */
    /* margin-right: 4px; */
}
.Torah_scrollMiddleContainer__XFhth {
    overflow: hidden;
    /* width: 539px; */
}
.Torah_scrollMiddleScrollingContainer__2kuiN {
    display: flex;
    flex-direction: row;
    -webkit-animation: Torah_scroll__3BLJG 5s linear both;
            animation: Torah_scroll__3BLJG 5s linear both;
    width: 200%;
}
.Torah_scrollMiddleTextContainer__1kaL- {
    /* display: contents; */
    position: relative;
}
.Torah_scrollMiddle__2sroW {
    height: 100%;
}
.Torah_scrollMiddleText__8pd7i {
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
}
.Torah_scrollMiddleText__8pd7i span[lang="he"] {
    font-family: 'Stam Ashkenaz CLM', serif;
    font-size: 500%;
}
.Torah_scrollMiddleText__8pd7i span[lang="en"] {
    font-family: 'Berry Rotunda', serif;
    font-size: 350%;
}

@-webkit-keyframes Torah_scroll__3BLJG {
    from {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes Torah_scroll__3BLJG {
    from {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}


@-webkit-keyframes Torah_scrollBounce__3UDrm {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    25% {
        -webkit-transform: translateY(17px);
                transform: translateY(17px);
    }
    61% {
        -webkit-transform: translateY(-24px);
                transform: translateY(-24px);
    }
    85% {
        -webkit-transform: translateY(-13px);
                transform: translateY(-13px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}


@keyframes Torah_scrollBounce__3UDrm {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    25% {
        -webkit-transform: translateY(17px);
                transform: translateY(17px);
    }
    61% {
        -webkit-transform: translateY(-24px);
                transform: translateY(-24px);
    }
    85% {
        -webkit-transform: translateY(-13px);
                transform: translateY(-13px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

