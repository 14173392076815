.bg::after {
    content: "";
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-color: #6c0f0f;
    /* background-image: url(../content/purimShared/art/transition.png);
    background-size: contain;
    background-position: center; */
    /* background-repeat: no-repeat; */
}

.afikomanBag {
    max-height: 60%;
    max-width: 30%;
    position: absolute;
    bottom: 10px;
    left: 20%;
}
.afikomanBagFront {
    z-index: 10;
}
.afikomanBagBack {
    z-index: 5;
}

.matzah {
    max-height: 20%;
    max-width: 10%;
    position: absolute;
    left: 85%;
    top: 80%;
    z-index: 7;
    visibility: visible;
    transition: all 2s;
    filter: drop-shadow(2px 2px 2px #333) drop-shadow(-1px -1px 2px #666)
}

.matzahPiecesContainer {
    width: 70vh;
    height: 70vh;
    max-width: 40vw;
    max-height: 40vw;
    position: absolute;
    top: 10px;
    right: 10px;
    transform-origin: center;
}
.matzahPiece {
    position: absolute;
    transition: transform 2s 0.5s, visibility 2s 4.5s;
}

.matzahPiecesContainer.center {
    width: 15vw;
    height: 15vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 4s;
    z-index: 20;
}
.matzahPiecesContainer.big {
    max-width: 75vh;
    max-height: 75vh;
    width: 60vw;
    height: 60vw;
    transform: rotate(3turn);
}
