.puzzleGrid {
    display: grid;
    /* grid-template-rows: repeat(3, fit-content(300px));
    grid-template-columns: repeat(3, fit-content(300px)); */
    /* max-height: calc(100% - 100px); */
    height: 100%;
    /* max-width: 986px; */
    min-width: 0;
    min-height: 0;
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
    grid-template-columns: 1fr repeat(3, minmax(0, 333px)) 1fr;
    grid-template-rows: repeat(3, minmax(0, 1fr));
    grid-template-areas: ". A B C ." ". D E F ." ". G H I .";
    /* justify-items: normal;
    align-items: center; */
}

.gridItem {
    background-color: #b4b4b4;
    border: 1px solid black;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
    width: 100%;
    justify-self: center;
}
/* .gridItem:nth-child(3n+1) {
    grid-column: content 1;
} */

.puzzleImage {
    max-width: 100%;
    height: auto;
    /* object-fit: contain; */
    height: 100%;
    width: 100%;
    z-index: 20;
}

.moving {
    position: absolute;
    transition: 2.5s;
    transition-property: left, top;
}


.locationsTrackContainer, .locationsTrackLabels {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 10px;
}
.locationsTrackBubble {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: 4px solid gray;
    margin: 0 -2px;
}
/* .locationsTrackBubble.newLocation {
    background-color: darkred;
} */
.locationsTrackMovingBubble {
    position: absolute;
    margin: 4px;
    width: 22px;
    height: 22px;
    border-radius: 20px;
    background-color: darkred;
    transition: 2s all;
}
.locationsTrackConnector {
    background-color: darkred;
    border: solid gray;
    border-width: 4px 0;
    height: 10px;
    flex: 1;
}
.locationsTrackLabels {
    justify-content: space-between;
    align-items: center;
}
.locationsTrackLabel {
    text-align: center;
    flex: 1;
    max-width: 100px;
    margin-left: -50px;
}
.locationsTrackLabel.newLocation {
    color: darkred;
}
.locationsTrackLabel:first-child {
    text-align: left;
    margin: 0;
    max-width: 75px;
}
.locationsTrackLabel:last-child {
    text-align: right;
    max-width: 75px;
}

@media (min-width: 1400px) {
    .locationsTrackLabel {
        max-width: 120px;
        margin-left: -60px;
    }
}
@media (min-width: 1600px) {
    .locationsTrackLabel {
        max-width: 150px;
        margin-left: -75px;
    }
    .locationsTrackLabel:first-child,
    .locationsTrackLabel:last-child {
        max-width: 100px;
    }
}


/* https://davidwalsh.name/css-flip */

/* entire container, keeps perspective */
.flipContainer {
    perspective: 1000px;
}

/* flip the pane */
.flipContainer.flipperBack .flipper {
    transform: rotateY(180deg);
}

/* .flipContainer,
.front,
.back {
    width: 320px;
    height: 480px;
} */

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
    height: 100%;
}
.flipContainer.flipperSlow .flipper {
    transition: 2s;
}

/* hide back of pane during swap */
.front,
.back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
}
