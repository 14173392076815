.clueText {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14pt;
}

.clueText p, .hintText p {
    margin: 12px 1px;
}

.clueText h3 {
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 0.5em;
}

.clueText h4 {
    font-size: 1.05em;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 0.2em;
}

.clueText a {
    color: #3182ce;
}
.clueText a:hover {
    color: #1e4e8c;
}

.clueText svg {
    display: inline;
}

.clueText.emBI em {
    font-weight: bold;
    font-style: italic;
    color: darkred;
}

.clueText .answerHint, .clueText .centeredClue {
    text-align: center;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
}

.clueText .answerHint>*,
.clueText .centeredClue>* {
    margin-left: auto;
    margin-right: auto;
}

.clueText p.centeredClue {
    display: block;
}

.clueText .answerHint {
    font-size: smaller;
    display: none;
}
/* hide all the underscores without going and editing them all */
.clueText .answerHint:last-child {
    display: block;
}

.clueText .centeredClue {
    font-size: larger;
    font-family: Arial, Helvetica, sans-serif;
}
.clueText .centeredClue.small {
    font-size: 1rem;
}

.listIndent {
    margin-left: 24px;
}

ul.listSimple li {
    list-style-type: initial;
    margin-left: 24px;
}

ul.listNone li {
    list-style-type: none;
}

ol.listSimple li {
    list-style-type: decimal;
    margin-left: 24px;
}

.listDashes li {
    list-style-type: square;
    margin-left: 24px;
}

.alphabetList li {
    list-style-type: upper-latin;
    margin-left: 24px;
}

[dir="rtl"] .listIndent,
[dir="rtl"] .listSimple li,
[dir="rtl"] .listDashes li,
[dir="rtl"] .alphabetList li {
    margin-left: auto;
    margin-right: 24px;
}

.letterTable {
    border-collapse: separate;
    border-spacing: 5px;
    text-align: center;
}

.letterTableBordered {
    margin: 4px;
}
.letterTableBordered td {
    border: 1px solid;
    padding: 5px;
}

.preserveWhitespace {
    white-space: pre-line;
}
.noWrap {
    white-space: nowrap;
}

.clueText hr {
    border-top-width: 1px;
    border-color: #333;
    margin: 12px auto;
    width: 50%;
}

.shukImageContainer img {
    display: inline-block;
    height: 100px;
    margin: 4px auto;
}
.clueText .citadelImageContainer img {
    display: inline-block;
    height: 75px;
    margin: 3px;
}

.tombsOfRighteousPathTable {
    margin-bottom: 1rem;
}

.tombsOfRighteousPathTable td {
    border: 1px solid #515151;
    padding: 3px;
    font-size: 1.1em;
    width: 60px;
    height: 60px;
    white-space: nowrap;
}
.tombsOfRighteousPathTable tr:nth-child(odd) {
    color: white;
}
.tombsOfRighteousPathTable tr:empty {
    height: 30px;
}
