.flameBox, .clue10TextBox {
    position: absolute;
    /* border: 2px solid black; */
    display: none;
    z-index: 5;
    transform-origin: top left;
}
.flame {
    transform: translate(-50%, 0%) scale(0.6);
    max-width: none;
}

.flameBox.ready, .clue10TextBox.ready {
    display: block;
}
.flameBox.ready .flame.up {
    transition: all 3s;
    transform: translate(-50%, -100%) scale(0.9);
    transform-origin: bottom center;
}

.clue9Text {
    /* display: none; */
    position: absolute;
    z-index: 30;
    opacity: 0;
    transform: translate(-50%, -50%);
    /* width: 0; */
    transition: opacity 3s;
    font-family: 'Niconne', cursive, serif;
}
.rtl .clue9Text {
    font-family: 'Dorian CLM Italic', serif;
    font-weight: bold;
}
.clue9Text.shown {
    opacity: 100;
    /* width: auto; */
    /* display: block; */
}
.clue9Text.shown::after {
    display: block;
    content: '';
    height: 1em;
    position: relative;
    top: -1.2em;
    margin-bottom: -1em;
    background: linear-gradient(to right, transparent, #e9ac59 10%);
    transform-origin: right center;
    animation: revealText 3s ease-out both;
}
.rtl .clue9Text.shown::after {
    background: linear-gradient(to left, transparent, #e9ac59 10%);
    transform-origin: left center;
}
.clue9Text.shown.tallCover::after {
    height: 2em;
    top: -1.8em;
    margin-bottom: -2.1em;
}
@keyframes revealText {
    0% {
        transform: scaleX(120%);
    }
    100% {
        transform: scaleX(0%);
    }
}

.clue10Text {
    transform: translate(-50%, -100%);
    text-align: center;
    font-size: 4em;
    font-weight: bold;
    /* display: block; */
    z-index: 20;
    opacity: 0;
    transition: all 3s;
    /* position: absolute; */
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}
.rtl .clue10Text {
    font-family: 'Niconne', cursive, serif;
    font-size: 8em;
    transform: translate(-40%, -90%);
}
.clue10Text.shown {
    opacity: 100;
}
.ltr .clue10TextBox:first-of-type .clue10Text {
    font-size: 3em;
    width: 2em;
    letter-spacing: 1em;
    word-break: break-all;
    transform: translate(-25%, 0);
    line-height: 1.2em;
}
.rtl .clue10TextBox:first-of-type .clue10Text {
    font-size: 12em;
    transform: translate(-40%, -80%);
}
.clue10TextBox {
    z-index: 20;
}

.gelt {
    display: block;
    position: absolute;
    z-index: 50;
    /* animation: geltFall linear both; */
    /* max-height: 100px; */
    opacity: 0;
}
@keyframes geltFall {
    0% {
        bottom: 100vh;
        opacity: 100;
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        opacity: 100;
    }
}
