.calendar {
    width: 90%;
    height: 90%;
    min-height: 500px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr;

    background-image: url('../content/highHolidaysShared/img/calendar-bg.jpg');
}

.day {
    /* height: 50px; */
    border: 1px solid #333;
    text-align: right;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.85);
}

.outOfRangeDays {
    background: none;
}

.dayDone {
    background-color: lightblue;
}
.dayActive {
    background-color: yellow;
}

.lockContainer {
    display: block;
    margin: auto;
    max-width: calc(100vh / 15);
    height: 100%;
    position: relative;
    left: -10%;
}
.lockContainer img {
    position: relative;
    top: 0;
    transform-origin: 88% center;
    transform-style: preserve-3d;
}
.lockContainer.lockClosed img:first-child {
    top: 10%;
}
.dayDone .lockContainer img:first-child {
    transform: rotateY(180deg);
}

.dayActive .lockContainer {
    width: 10%;
    animation: lockGrow 3s 1s forwards;
}
.dayActive .lockContainer img:first-child {
    top: 10%;
    animation: openLock 3s 3s forwards;
    /* transition: 1s transform; */
}

@keyframes openLock {
    0% {
        transform: none;
    }
    33% {
        top: 0;
        transform: none;
    }
    100% {
        top: 0;
        transform: rotateY(180deg);
    }
}
@keyframes lockGrow {
    to {
        width: 100%;
    }
}


.container.finalLevel {
    position: relative;
    animation: revealScroll 3s 8s forwards;
}

@keyframes revealScroll {
    from {
        top: 0;
    }
    to {
        top: -100vh;
    }
}

.endMessageTitle {
    position: relative;
    color: white;
    -webkit-text-stroke: 0.1rem black;
    font-weight: bold;
}
