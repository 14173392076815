.bg::after {
    content: "";
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-image: url(../content/purimShared/art/transition.png);
    background-size: contain;
    background-position: center;
    /* background-repeat: no-repeat; */
}

.scrollRight, .scrollLeft {
    position: absolute;
    top: 0;
    height: 100%;
}
.scrollRight {
    right: 0;
}
.scrollLeft {
    transition: right 2s;
    /* margin-right: 4px; */
}

.withBorder {
    --stroke-radius: 1px;
    animation: borderGrow 2s 0.1s ease-in both;
}

@keyframes borderGrow {
    from {
        filter: drop-shadow(1px 0 var(--stroke-radius) transparent)
                drop-shadow(-1px var(--stroke-radius) transparent)
                drop-shadow(0 1px var(--stroke-radius) transparent)
                drop-shadow(0 -1px var(--stroke-radius) transparent)
                drop-shadow(1px 1px var(--stroke-radius) transparent)
                drop-shadow(1px -1px var(--stroke-radius) transparent)
                drop-shadow(-1px 1px var(--stroke-radius) transparent)
                drop-shadow(-1px -1px var(--stroke-radius) transparent);
    }

    to {
        filter: drop-shadow(1px 0 var(--stroke-radius) #00ccfb)
                drop-shadow(-1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 1px var(--stroke-radius) #00ccfb)
                drop-shadow(0 -1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(1px -1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px 1px var(--stroke-radius) #00ccfb)
                drop-shadow(-1px -1px var(--stroke-radius) #00ccfb);
    }
}

.hamantash {
    display: block;
    position: absolute;
    left: 20vw;
    z-index: 50;
    visibility: hidden;
    transform-origin: top right;
}

@keyframes hamantashAppear {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

.hamantashBig {
    display: block;
    position: absolute;
    left: 20vw;
    z-index: 50;
    animation: hamantashGrow 3s linear both;
    /* visibility: hidden; */
    transform-origin: center;
}

@keyframes hamantashGrow {
    0% {
        transform: rotate(1deg) scale(0.3) translateX(-25%);
    }

    100% {
        left: 40vw;
        transform: rotate(540deg) scale(2) translateX(0);
    }
}
