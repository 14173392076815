.mapCircle {
    position: absolute;
    border: 2px solid black;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 10;
}
.mapCircle.ready {
    display: block;
    transition: all 3s;
}
.mapCircle.exit {
    background-color: #072f7b;
    /* border-color: #072f7b; */
    transition: all 5s;
}
