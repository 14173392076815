.pulser {
    position: absolute;
    /* border: 2px solid black; */
    transform: translate(-50%, -50%);
    display: none;
    z-index: 10;
}
.pulser.ready {
    display: block;
    transition: all 3s;
}

.pulser {
    background-color: #fff;
    color: #80cfff;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    box-shadow: 0 0 8px, inset 0 0 8px;
    animation: pulse 2s linear 1s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 8px, inset 0 0 8px;
    }

    50% {
        box-shadow: 0 0 16px, inset 0 0 14px;
    }

    100% {
        box-shadow: 0 0 8px, inset 0 0 8px;
    }
}

/* based on https://codepen.io/laverboy/pen/iEDcG */
