.mainContainer {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.mainContainerDialog {
    transition: height 0.2s, width 0.2s;
}
.mainContainerDialog.resizing {
    transition: none;
}

.mainContent {
    width: 100%;
    /*! margin: auto; */
    /* border: 1px solid #d9d9d9; */
    /*! flex: 1 1; */
    /*! position: absolute; */
    z-index: 10;
    /* background: rgba(100,100,100,0.8); */
    /*! bottom: 5px; */
    /*! align-self: flex-end; */
    /* margin-bottom: 5px;
    padding: 0px 10px; */
}
.collapseTextButton, .openTextButton {
    display: block;
    margin: auto;
    border-style: solid;
    height: 15px;
    line-height: 10px;
    width: 80px;
}
.openTextButton {
    z-index: 10;
    margin-bottom: 5px;
}

/* .imgContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;

    background: url(./art/ben-gurion-airport.png) no-repeat;
    background-size: cover;
    /* background-position: bottom -20px center; * /
}*/
